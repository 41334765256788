import React, { useState } from 'react';
import './ContactSection.css';

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [statusMessage, setStatusMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage('');
    try {
      const domain = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://www.anov8.com';
      const response = await fetch(`${domain}/nwtl/V02/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatusMessage('Message sent successfully!');
        setMessageType('success');
        setFormData({
          name: '',
          email: '',
          message: '',
        });
      } else {
        const errorData = await response.json();
        setStatusMessage(errorData.message);
        setMessageType('error');
      }
    } catch (error) {
      setStatusMessage('An error occurred while sending your message.');
      setMessageType('error');
      console.error(error);
    }
  };

  return (
    <section id="contact" className="contact transparent-box">
      <h2>Contact Us</h2>
      {statusMessage && (
        <div className={`status-message ${messageType}`}>
          {statusMessage}
        </div>
      )}
        {(!statusMessage ) && (
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <textarea
          name="message"
          placeholder="Your Message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
        <button type="submit" className="custom-button">
          Send Message
          </button>
        </form>
      )}
    </section>
  );
};

export default ContactSection;

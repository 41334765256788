import React, { useState } from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import ContactSection from './components/ContactSection';
import TokenForm from './components/TokenForm';
import './App.css';
import ProductFeaturesSection from './components/ProductFeaturesSection';
import './Global.css';

function App() {
  // State to control whether the TokenForm modal is open or closed
  const [openModal, setOpenModal] = useState(false);

  // Function to open the modal
  const handleOpenPopup = () => {
    setOpenModal(true);  // Set the state to true, opening the modal
  };

  // Function to close the modal
  const handleClosePopup = () => {
    setOpenModal(false);  // Set the state to false, closing the modal
  };

  return (
    <div>
      <Header /> {/* Header with navigation links */}
      <div className="container"> {/* Add a container class */}
      <HeroSection />
      
      {/* Pass the handleOpenPopup function to ProductSection to trigger the modal */}
      <ProductFeaturesSection onProductClick={handleOpenPopup} />
      {/* Render TokenForm as a modal, controlled by openModal */}
      <TokenForm open={openModal} handleClose={handleClosePopup} />
      
      <ContactSection />
    </div>
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Dialog, DialogContent, Grid, Button, TextField, Typography } from '@mui/material';
import './TokenForm.css';

function TokenForm({ open, handleClose }) {
  // **State Hooks for Form Fields and CAPTCHA**
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);

  // **Handle CAPTCHA Change**
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Ensure the user has completed the CAPTCHA
    if (!captchaValue) {
        alert('Please complete the CAPTCHA');
        return;
    }

    try {
        // Send form data and CAPTCHA token to your server
        const domain = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://www.anov8.com';
        const response = await fetch(`${domain}/nwtl/V02/expressinterest`, {
  
        
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                email, 
                name, 
                captchaValue // Include CAPTCHA token
            }),
        });

        const result = await response.json();

        if (response.ok) {
            alert(result.message);
            // Reset form and CAPTCHA
            setEmail('');
            setName('');
            setCaptchaValue(null);
        } else {
            alert(result.message || 'CAPTCHA validation failed');
        }
    } catch (error) {
        console.error('Error during form submission:', error);
        alert('An error occurred while submitting the form');
    }
};


  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      fullWidth 
      maxWidth="md" 
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: '#05060fd9',  // Dark background for the modal
          color: '#ffffff',              // White text color
          borderRadius: '15px',          // Rounded corners
          padding: '30px',               // Internal padding
          boxShadow: '0 4px 15px rgba(0, 0, 0, 0.5)', // Soft shadow for depth
        },
      }}
    >
      <DialogContent>
        <Typography 
          variant="h4" 
          align="center" 
          gutterBottom
          sx={{
            fontFamily: 'Urbanist, sans-serif',
            fontSize: '28px',
            fontWeight: '700',
            marginBottom: '15px',
            color: '#ffffff',
          }}
        >
          Register Interest
        </Typography>
        
        <Typography 
          variant="body1" 
          align="center" 
          gutterBottom
          sx={{
            fontFamily: 'Urbanist, sans-serif',
            fontSize: '16px',
            color: '#cfcfcf',
            marginBottom: '30px',
          }}
        >
          Please enter your access token to proceed, or register your interest if you don't have a token yet.
        </Typography>

        {/* Wrapping Grid to center the content */}
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={8}>
            <Typography 
              variant="h5" 
              mb={3} 
              align="center"
              sx={{
                fontFamily: 'Urbanist, sans-serif',
                fontSize: '22px',
                color: '#ffffff',
              }}
            >
              To receive further information, sign up with your email
            </Typography>
            
            <form onSubmit={handleSubmit}>
              <TextField 
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth 
                sx={{
                  marginBottom: '20px',
                  '& .MuiInputBase-input': {
                    backgroundColor: '#1c1b22b5',  // Dark background for input
                    color: '#ffffff',           // White text
                    borderRadius: '8px',         // Rounded corners
                    padding: '12px',             // Padding inside input field
                  },
                  '& .MuiFormLabel-root': {
                    color: '#cfcfcf',            // Label color
                    fontSize: '14px',
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#61dafb',      // Blue border when focused
                  },
                }}
              />
              
              <TextField 
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth 
                sx={{
                  marginBottom: '20px',
                  '& .MuiInputBase-input': {
                    backgroundColor: '#1c1b22b5',
                    color: '#ffffff',
                    borderRadius: '8px',
                    padding: '12px',
                  },
                  '& .MuiFormLabel-root': {
                    color: '#cfcfcf',
                    fontSize: '14px',
                  },
                }}
              />

              {/* Add the reCAPTCHA component */}
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <ReCAPTCHA
                  sitekey="6LdsP48qAAAAAE_vs8YZB8Il4f3u49PvIMufO6_L" // Replace with your actual Site Key
                  onChange={handleCaptchaChange}
                  theme="dark" // Matches your form's dark theme
                />
              </div>
              
              <Button 
                type="submit"
                variant="contained" 
                fullWidth 
                sx={{
                  backgroundColor: '#895be05e',
                  color: 'white',
                  borderRadius: '8px',
                  padding: '10px 20px',
                  '&:hover': {
                    backgroundColor: '#21a1f1',  // Darker blue on hover
                  },
                }}
              >
                Submit
              </Button>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default TokenForm;
